<template>
  <div
    class="virtual-list-wrapper -paragraphs"
    :class="{ hidden: !isParagraphsReady }"
    @click="setFocus"
    v-hotkey.stop="keymap"
    @keydown.space.exact="$_keymapHandlerDown"
    @keydown.space.shift.exact="$_keymapHandlerUp"
    v-vue-touch:swipe="navigate"
  >
    <div class="hollow-dots-spinner" v-if="isSpinnerVisible">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <virtual-list
      :key="renderScrollTrigger"
      v-focus="focus.paragraphs"
      ref="virtualList"
      tabindex="200"
      class="virtual-list"
      :class="{
        '-exact-search': isExactSearch,
      }"
      :keeps="50"
      :data-sources="paragraphs"
      :data-key="'id'"
      :data-component="paragraphItem"
      :extra-props="{
        handleParagraphEvent: handleParagraphEvent,
        handleParaMouseDownEvent: handleParaMouseDownEvent,
        handleParaMouseUpEvent: handleParaMouseUpEvent,
        handleParaTouchStartEvent: handleParaTouchStartEvent,
        handleParaTouchEndEvent: handleParaTouchEndEvent,
      }"
      @totop="handleScrollToTop"
      @tobottom="handleScrollToBottom"
      @scroll="handleScroll"
      :top-threshold="100"
      :bottom-threshold="600"
    >
    </virtual-list>
  </div>
</template>

<script>
  import virtualList from 'vue-virtual-scroll-list';
  import { focus } from 'vue-focus';
  import debounce from 'lodash/debounce';

  import { mapState } from 'vuex';
  import {
    SCROLL_DOWN,
    SCROLL_UP,
    SPACE_SCROLL_UP,
    SPACE_SCROLL_DOWN,
    FOCUS_SELECTOR_PARAGRAPHS,
  } from '@/constants/constants';

  import ParagraphItem from '@/containers/paragraphs/ParagraphItem.vue';

  import CopyService from '@/services/CopyService.js';
  import ExternalLinkService from '@/services/ExternalLinkService.js';
  import eventManager from '@/services/EventService';

  import layoutUtils from '@/utils/layoutUtils';

  import EventEnum from '@/enums/EventEnum';
  import * as log from 'loglevel';

  log.setLevel('info');

  const TOUCH_DELTA = 5;
  const PARAGRAPHS_RENDER_DEBOUNCE_TIMEOUT = 50;
  const APPLY_NEW_HIT_TIMEOUT = 100;
  const NAVIGATE_DEBOUNCE = 100;

  let touchStartY;
  let touchEndY;
  let scrollDirection;
  let prevScrollParaId = null;

  let selection;
  let paragraphTimeout = null;
  let applyNewHitTimeout = null;
  let isScrollBarDragging = false;
  let lastScrollTop = null;
  let selectionChangeTimeout = null;

  const CONTAINER_SELECTOR = '.-paragraphs';

  export default {
    name: 'Paragraphs',
    directives: {
      focus: focus,
    },
    components: {
      'virtual-list': virtualList,
    },
    props: {
      hitParagraphs: {
        type: Array,
      },
    },
    data() {
      return {
        paragraphItem: ParagraphItem,
        isSpinnerVisible: false,
        isParagraphsReady: false,
        isNewActiveHit: true,
        locator: null,
        subscribeAction: null,
        renderScrollTrigger: 0,
        isExactSearch: false,
      };
    },
    computed: {
      ...mapState('SwParagraphsStore', [
        'paragraphs',
        'loadParagraphsInProcess',
        'renderStartIndex',
        'isBookStart',
        'isBookEnd',
      ]),
      ...mapState('SwHitsStore', ['activeHit']),
      ...mapState('SwContextStore', [
        'brand',
        'lang',
        'focus',
        'mainPopupOpened',
      ]),
      ...mapState('SwSearchStore', ['parsedResponse']),
      ...mapState('SwSelectionContextStore', [
        'selectionStartContainerSelector',
      ]),

      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          up: () => {
            this.$_keymapHandlerUp();
          },
          down: () => {
            this.$_keymapHandlerDown();
          },
        };
      },
      isParagraphsCollapsed() {
        return this.$store.getters['SwContextStore/isParagraphsCollapsed'];
      },
    },
    watch: {
      activeHit: {
        handler: function(newHit, oldHit) {
          if (newHit?.id !== oldHit?.id) {
            this.$_setInitialLocalState();
            this.isParagraphsReady = false;
          }
        },
      },
      paragraphs() {
        if (!scrollDirection) {
          this.isNewActiveHit = true;
          applyNewHitTimeout = setTimeout(() => {
            this.$_applyParagraphScroll();
          }, APPLY_NEW_HIT_TIMEOUT);
        } else if (scrollDirection === SCROLL_UP) {
          this.renderScrollTrigger++;
        }
      },
      loadParagraphsInProcess(inProcess) {
        if (!inProcess) {
          this.isSpinnerVisible = false;
        }
      },
      isParagraphsCollapsed(val) {
        if (val) {
          return;
        }

        const force = this.paragraphs.length !== 0;
        this.$_applyParagraphScroll(force);
      },
    },
    mounted() {
      this.$_subscribe();
      const force = this.paragraphs.length !== 0;
      this.$_applyParagraphScroll(force);
      document.addEventListener('selectionchange', this.handleSelectionChange);
    },
    destroyed() {
      this.$_unsubscribe();
      this.$_clearTimeouts();
      document.removeEventListener(
        'selectionchange',
        this.handleSelectionChange
      );
    },
    methods: {
      handleParagraphEvent({ type, data }) {
        switch (type) {
          case EventEnum.PARAGRAPH_MOUNTED: {
            if (
              data.paraId === prevScrollParaId &&
              scrollDirection === SCROLL_UP
            ) {
              this.$_setCurrentScrollPosition();
            }
            this.$_applyParagraphScroll();
          }
        }
      },
      handleParaMouseDownEvent(event) {
        this.$_handleSelectionStart(event);
      },
      handleParaMouseUpEvent(event) {
        this.$_handleSelectionEnd(event);
      },
      handleParaTouchStartEvent(event) {
        if (!this.$_isTouchDevice()) {
          return;
        }
        this.$_handleSelectionStart(event);
      },
      handleParaTouchEndEvent() {},
      handleSelectionChange(event) {
        if (
          this.selectionStartContainerSelector !== CONTAINER_SELECTOR ||
          !this.$_isTouchDevice() ||
          (!this.$_isInsideShadowRoot() &&
            !this.$el.contains(document.getSelection().anchorNode)) ||
          !document.getSelection()?.toString()?.length
        ) {
          return;
        }
        clearTimeout(selectionChangeTimeout);
        selectionChangeTimeout = setTimeout(() => {
          const range = window.getSelection().getRangeAt(0);

          const rangeClientRects = range.getClientRects();

          const startEvent = this.$_recalculateSelectionEvent(
            event,
            rangeClientRects[0]
          );
          this.$_handleSelectionStart(startEvent);

          const endEvent = this.$_recalculateSelectionEvent(
            event,
            rangeClientRects[rangeClientRects.length - 1],
            rangeClientRects[rangeClientRects.length - 1].width
          );
          this.$_handleSelectionEnd(endEvent);
        }, 100);
      },
      $_applyParagraphScroll(force) {
        this.$_clearTimeouts();

        paragraphTimeout = setTimeout(() => {
          if (this.isNewActiveHit || force) {
            this.$_setInitialScrollPosition();
            this.isExactSearch = !!this.$el.querySelector('.search-quote');
          }

          if (scrollDirection === SCROLL_UP) {
            this.$_setCurrentScrollPosition();
          }

          this.isParagraphsReady = true;
        }, PARAGRAPHS_RENDER_DEBOUNCE_TIMEOUT);
      },
      $_clearTimeouts() {
        clearTimeout(paragraphTimeout);
        clearTimeout(applyNewHitTimeout);
      },
      $_subscribe() {
        this.$el.addEventListener('wheel', this.handleOnWheelEvent, {
          passive: true,
        });
        this.$el.addEventListener('mousedown', this.handleOnMouseDownEvent);
        this.$el.addEventListener('mouseup', this.handleOnMouseUpEvent);
        this.$el.addEventListener('touchstart', this.handleOnTouchStartEvent, {
          passive: true,
        });
        this.$el.addEventListener('touchend', this.handleOnTouchEndEvent);
        this.$el.addEventListener('keydown', this.handleOnKeyDownEvent);
        this.$el.addEventListener('copy', this.handleCopyEvent);

        this.subscribeAction = this.$store.subscribeAction(action => {
          switch (action.type) {
            case 'SwHitsStore/resetHitState':
              this.$_scrollToSelection();
              break;
          }
        });
      },
      $_unsubscribe() {
        this.$el.removeEventListener('wheel', this.handleOnWheelEvent);
        this.$el.removeEventListener('mousedown', this.handleOnMouseDownEvent);
        this.$el.removeEventListener('mouseup', this.handleOnMouseUpEvent);
        this.$el.removeEventListener(
          'touchstart',
          this.handleOnTouchStartEvent
        );
        this.$el.removeEventListener('touchend', this.handleOnTouchEndEvent);
        this.$el.removeEventListener('keydown', this.handleOnKeyDownEvent);
        this.$el.removeEventListener('copy', this.handleCopyEvent);

        if (this.subscribeAction) {
          this.subscribeAction();
        }
      },
      setFocus() {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_PARAGRAPHS,
        });
      },
      handleScrollToTop: debounce(function() {
        if (this.loadParagraphsInProcess || this.isBookStart) {
          return;
        }
        this.$_clearTimeouts();
        this.$_saveCurrentScrollPosition();
        this.isSpinnerVisible = true;
        this.$store.dispatch('SwParagraphsStore/performParagraphsSearchPrev');
      }, 50),
      handleScrollToBottom() {
        if (this.loadParagraphsInProcess || this.isBookEnd) {
          return;
        }
        this.$store.dispatch('SwParagraphsStore/performParagraphsSearchNext');
      },
      handleScroll(event) {
        if (!isScrollBarDragging) {
          return;
        }

        const currentScrollTop = event.target.scrollTop;
        if (currentScrollTop > lastScrollTop) {
          scrollDirection = SCROLL_DOWN;
        } else if (currentScrollTop < lastScrollTop) {
          scrollDirection = SCROLL_UP;
        }
        lastScrollTop = currentScrollTop;
      },
      $_saveCurrentScrollPosition() {
        if (this.$refs.virtualList) {
          prevScrollParaId = this.paragraphs[0]?.id;
        }
      },
      handleOnWheelEvent(event) {
        if (!event) {
          return;
        }
        this.$_setScrollDirectionByDeltaY(event.deltaY);
      },
      handleOnMouseDownEvent(event) {
        const isScrollbarClicked = event.target.classList.contains(
          'virtual-list'
        );

        if (!event || !isScrollbarClicked) {
          return;
        }
        event.preventDefault();

        const scrollBarCoords = this.$_getScrollBarCoords();
        if (
          scrollBarCoords.left <= event.clientX &&
          scrollBarCoords.right >= event.clientX
        ) {
          isScrollBarDragging = true;
        }
      },
      handleOnMouseUpEvent() {
        if (isScrollBarDragging) {
          isScrollBarDragging = false;
        }
      },
      handleOnTouchStartEvent(event) {
        if (!event) {
          return;
        }
        this.$_setTouchstartPosition(event);
      },
      handleOnTouchEndEvent(event) {
        if (!event) {
          return;
        }
        this.$_setScrollDirectionByTouches(event);
      },

      handleOnKeyDownEvent(event) {
        switch (event.keyCode) {
          case 38:
          case 33:
          case 36:
            scrollDirection = SCROLL_UP;
            break;
          case 40:
          case 34:
          case 35:
            scrollDirection = SCROLL_DOWN;
            break;
        }
      },

      $_handleSelectionStart(event) {
        if (this.$_isRightMouseClickTriggered(event)) {
          return;
        }
        this.$store.dispatch('SwSelectionContextStore/setSelectionStart', {
          bookMeta: this.activeHit?.bookMeta,
          id: this.activeHit?.id,
          selectionStartEvent: event,
          container: this.$el,
          containerSelector: CONTAINER_SELECTOR,
        });
      },

      async $_handleSelectionEnd(event) {
        if (this.$_isRightMouseClickTriggered(event)) {
          return;
        }

        await this.$store.dispatch('SwSelectionContextStore/setSelectionEnd', {
          bookMeta: this.activeHit?.bookMeta,
          selectionEndEvent: event,
          container: this.$el,
        });
        await this.$_setSelection();
      },

      $_setSelection() {
        const selectionText = window.getSelection().toString();
        return this.$store.dispatch(
          'SwSelectionContextStore/setSelectionText',
          selectionText
        );
      },

      $_setScrollDirectionByDeltaY(deltaY) {
        scrollDirection = deltaY < 0 ? SCROLL_UP : SCROLL_DOWN;
      },

      $_setTouchstartPosition(event) {
        if (event && event.touches[0]) {
          touchStartY = event.touches[0].clientY;
        }
      },

      $_setScrollDirectionByTouches(event) {
        if (!event.changedTouches[0]) {
          return;
        }

        touchEndY = event.changedTouches[0].clientY;

        if (touchStartY > touchEndY + TOUCH_DELTA) {
          scrollDirection = SCROLL_DOWN;
        } else if (touchStartY < touchEndY - TOUCH_DELTA) {
          scrollDirection = SCROLL_UP;
        }
      },

      $_getScrollBarCoords() {
        const coords = {
          left: 0,
          right: 0,
        };
        const virtualListElement = this.$el.querySelector('.virtual-list');
        if (!virtualListElement) {
          return coords;
        }

        const virtualListElementRect = virtualListElement.getBoundingClientRect();
        coords.left =
          virtualListElementRect.left + virtualListElement.clientWidth;
        coords.right =
          virtualListElementRect.left + virtualListElement.offsetWidth;

        return coords;
      },

      scrollToFirstVisible() {
        const virtualListElement = this.$el.querySelector('.virtual-list');
        let selection = this.$el.querySelectorAll('.paragraph-number-first');
        selection = selection?.length === 1 ? selection[0] : selection[1];
        selection = selection?.parentElement;
        if (virtualListElement && selection) {
          selection.scrollIntoView(true);
          virtualListElement.scrollTop -= 10;
        }
      },

      $_getParagraphNumber() {
        if (!selection || !selection.para) {
          return false;
        }

        return selection.para.html.match(/>(\d+(\.\d+)?)</)
          ? selection.para.html.match(/>(\d+(\.\d+)?)</)[1]
          : '';
      },

      $_getShortenedLink(bookMeta, externalLink) {
        try {
          return ExternalLinkService.getShortenedLink(
            this.locator,
            bookMeta,
            externalLink
          );
        } catch (error) {
          log.warn(
            `Unable to get shortened link for selected content with ${error}`
          );
          return externalLink;
        }
      },

      navigate: debounce(function(direction) {
        const isSelection = !!window.getSelection().toString().length;

        if (!layoutUtils.isMobile() || isSelection) {
          return;
        }

        switch (direction) {
          case 'right':
            eventManager.publish(EventEnum.NAVIGATE_BACK);
            break;
          case 'left':
            eventManager.publish(EventEnum.NAVIGATE_FORWARD);
            break;
        }
      }, NAVIGATE_DEBOUNCE),

      $_keymapHandlerUp() {
        if (this.focus && this.focus.paragraphs) {
          scrollDirection = SCROLL_UP;
          eventManager.publish(SPACE_SCROLL_UP);
        }
      },

      $_keymapHandlerDown() {
        if (this.focus && this.focus.paragraphs) {
          scrollDirection = SCROLL_DOWN;
          eventManager.publish(SPACE_SCROLL_DOWN);
        }
      },

      $_setInitialScrollPosition() {
        if (!this.$refs.virtualList) {
          return;
        }
        this.$_scrollToSelection();
        this.isParagraphsReady = true;
        this.isNewActiveHit = false;
      },

      $_setCurrentScrollPosition() {
        if (!this.$refs.virtualList) {
          return;
        }
        this.$_scrollToLastScrollPosition();
      },

      $_scrollToSelection() {
        const scrollElement = this.$el.querySelector('.virtual-list');
        const selectionElement = this.$el.querySelector('.search-sentence');

        if (scrollElement && selectionElement) {
          selectionElement.scrollIntoView({ block: 'start' });

          const virtualListElementRect = scrollElement.getBoundingClientRect();
          const scrollElementRect = selectionElement.getBoundingClientRect();
          const scrollDelta = virtualListElementRect.height / 3;

          if (
            Math.abs(virtualListElementRect.top - scrollElementRect.top) <
            scrollDelta
          ) {
            scrollElement.scrollBy({
              top: -scrollDelta,
            });
            return true;
          }
        }
        return false;
      },

      async $_scrollToLastScrollPosition() {
        if (!prevScrollParaId) {
          return;
        }
        await this.$nextTick();
        this.$el
          .querySelector(`[data-sp-id="${prevScrollParaId}"]`)
          ?.scrollIntoView();
      },

      $_setInitialLocalState() {
        this.$_clearTimeouts();
        touchStartY = 0;
        touchEndY = 0;
        scrollDirection = null;
        paragraphTimeout = null;
        applyNewHitTimeout = null;
        prevScrollParaId = null;
      },

      handleCopyEvent(event) {
        event.preventDefault();

        const selectionContext = this.$store.getters[
          'SwSelectionContextStore/getSelectionContext'
        ];
        CopyService.copySelectionContextToClipboard(
          event,
          selectionContext,
          () => {
            this.$store.dispatch(
              'SwSelectionContextStore/resetSelectionContext'
            );
            window.getSelection().removeAllRanges();
          }
        );
      },
      $_isRightMouseClickTriggered(event) {
        return event?.which === 3;
      },
      $_isTouchDevice() {
        return 'ontouchstart' in window;
      },
      $_isInsideShadowRoot() {
        return this.$el.getRootNode() instanceof ShadowRoot;
      },
      $_recalculateSelectionEvent(event, clientRect, xOffset = 0) {
        const _event = { ...event };
        _event.clientX = clientRect.x + xOffset;
        _event.clientY = clientRect.y + 10;
        _event.target = event.target;

        return _event;
      },
    },
  };
</script>

<style lang="less" src="./Paragraphs.less"></style>
