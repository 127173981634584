import get from 'lodash/get';
import * as log from 'loglevel';
import { TITLE_KEY_SEPARATOR } from '@/constants/constants.js';

import ParseResponseUtils from '@/services/ElasticSearch/ParseResponseUtils';

const parseInitSearchResponse = ({ responses }) => {
  return {
    searchInfo: get(responses[0], 'data', null),
    esInfoMap: responses[1] || null,
  };
};

const parseRemoteConfigResponse = ({ response }) => {
  return get(response, 'data', null);
};

const parseParagraphsResponse = ({ response }) => {
  const prevParagraphs = response[0];
  const nextParagraphs = response[1];
  return ParseResponseUtils.mergePrevNextPara(prevParagraphs, nextParagraphs);
};

const parseParagraphsToMapWithoutParaId = ({ response }) => {
  if (response.data && response.data.hits) {
    response.data.hits.hits = response.data.hits.hits.map((rawDoc, index) => {
      const doc = rawDoc._source;
      const locator = doc.locator || '';
      const paraNum = locator.split('.')[0];
      doc.paraId =
        typeof paraNum === 'string' && paraNum.length > 0
          ? `para_${paraNum}`
          : `para_${index}`;
      return rawDoc;
    });
    return parseParagraphsToMap({ response });
  }
};

const parseFoundPublicationIdsResponse = ({ response }) => {
  const pubIdsBuckets = get(
    response,
    'data.aggregations.publicationIds.buckets',
    []
  );
  return pubIdsBuckets.reduce((acc, bucket) => {
    return { ...acc, [bucket.key]: true };
  }, {});
};

const parseParagraphsToMap = ({ response }) => {
  const paragraphs = get(response, 'data.hits.hits', null);

  const paraMap = {};
  const paraIds = [];

  if (paragraphs) {
    paragraphs.forEach(paraDoc => {
      const highlightTextExact = get(paraDoc, 'highlight["text.exact"]', []);
      const highlightText = highlightTextExact.length
        ? highlightTextExact
        : get(paraDoc, 'highlight.text', []);
      if (highlightText.length > 1) {
        log.error(`In creating fn parseParagraphsToMap get more then one highlight sentence,
          need increase 'number_of_fragments' and 'fragment_size' in query
          addHighlightQuery for get one sentence`);
      }
      const doc = paraDoc._source;
      if (highlightText[0]) {
        doc.text = highlightText[0];
      }
      const paraId = doc.paraId;
      if (!paraMap.hasOwnProperty(paraId)) {
        paraMap[paraId] = [];
      }
      paraMap[paraId].push(paraDoc._source);
      paraIds.push(paraId);
    });
  }

  paraIds.forEach(paraId => {
    paraMap[paraId].sort((sentA, sentB) => {
      return sentA.sentenceNumber - sentB.sentenceNumber;
    });
  });

  return paraMap;
};

const parseInitialFilters = response => {
  const output = {};

  for (const hit of response.hits.hits) {
    const doc = hit._source;

    if (
      !doc.titleNormalized ||
      !doc.titleNormalizedLabel ||
      !doc.authorNormalized ||
      !doc.authorNormalizedLabel ||
      !doc.categoryNormalized ||
      !doc.categoryNormalizedLabel
    ) {
      continue;
    }

    if (!output[doc.categoryNormalized]) {
      output[doc.categoryNormalized] = {
        info: { label: doc.categoryNormalizedLabel },
        data: [],
      };
    }

    const category = output[doc.categoryNormalized];

    // Add the author/collection under the category
    const isCollection = !!doc.collectionTitleNormalized;
    const authorKey = isCollection
      ? doc.collectionTitleNormalized
      : doc.authorNormalized;
    const authorLabel = isCollection
      ? doc.collectionTitleNormalizedLabel
      : doc.authorNormalizedLabel;

    let author = category.data.find(
      item => item.info.labelNormalized === authorKey
    );

    if (!author) {
      author = {
        info: {
          label: authorLabel,
          labelNormalized: authorKey,
          collectionTitle: isCollection
            ? doc.collectionTitleNormalizedLabel
            : '',
          collectionTitleNormalized: isCollection
            ? doc.collectionTitleNormalized
            : '',
          isCollection,
        },
        data: [],
      };
      category.data.push(author);
    }

    // Add the title under the author/collection
    const titleKey = `${TITLE_KEY_SEPARATOR}${doc.publicationId}${TITLE_KEY_SEPARATOR}${doc.titleNormalized}`;
    let title = author.data.find(
      item => item.info.labelNormalized === titleKey
    );

    if (!title) {
      title = {
        info: {
          publicationId: doc.publicationId,
          label: doc.titleNormalizedLabel,
          labelNormalized: titleKey,
          authorName: doc.authorNormalizedLabel,
          isCollection,
        },
        data: [],
      };
      author.data.push(title);
    }
  }
  return output;
};

const parseTitlesResponse = ({ response }) => {
  const hits = get(response, 'data.hits.hits', []);
  let parseTitlesResponse = [];

  hits.forEach(hit => {
    let parsedInnerHits = get(hit, 'inner_hits.cluster.hits.hits', []);

    parsedInnerHits = parsedInnerHits.map((innerHit, index) => {
      const parsedInnerHit = { ...innerHit._source };

      if (index === 0) {
        parsedInnerHit.firstGroupItem = true;
      }
      parsedInnerHit.highlight = get(innerHit, 'highlight.text', [
        parsedInnerHit.text,
      ]).join(' ... ');
      return parsedInnerHit;
    });
    parseTitlesResponse = [...parseTitlesResponse, ...parsedInnerHits];
  });
  return {
    totalBooks: get(response, 'data.aggregations.totalPublications.value', 0),
    titlesList: parseTitlesResponse,
  };
};

const parseNavigationResponse = ({ response }) => {
  const hits = get(response, 'data.hits.hits', []);
  return hits.map(hit => hit._source);
};

const parseSingleHitResponse = ({ response }) => {
  return get(response, 'data.hits.hits[0]._source', {});
};

const parseHitsResponse = ({ response, supplementalSummary, collapsed }) => {
  const sentences = get(response, 'data.hits.hits', []);
  const indexName = get(response, 'data.hits.hits[0]', {})._index;

  const stems = {};
  const quotes = {};

  let parsedSentences = collapsed
    ? ParseResponseUtils.createClusteredHitsList(sentences)
    : sentences;
  parsedSentences = parsedSentences.map(sentence => {
    const parsedSentence = { ...sentence._source };

    const summary = ParseResponseUtils.getSummaryByIndexName(
      supplementalSummary,
      indexName
    );

    const highlight = get(sentence, 'highlight.text', [
      parsedSentence.text,
    ]).join(' ... ');

    const quoteHighlight = get(sentence, 'highlight.["text.exact"]', [
      parsedSentence.text,
    ]).join(' ... ');

    const highlightedStems = ParseResponseUtils.getStems(highlight);
    const highlightedQuotes = ParseResponseUtils.getQuotes(quoteHighlight);

    ParseResponseUtils.addToMap(stems, highlightedStems);
    ParseResponseUtils.addToMap(quotes, highlightedQuotes);

    if (summary) {
      parsedSentence.type = 'Supplemental';
    }

    const sentenceHighlight =
      highlightedQuotes.length === 0 ? highlight : quoteHighlight;

    return {
      ...parsedSentence,
      ...{
        highlight: sentenceHighlight,
      },
    };
  });

  const quotesArr = Object.keys(quotes).map(function(quote) {
    return quote.split(/\s+/g).map(word => [word]);
  });
  const stemsArr = Object.keys(stems);

  return {
    indexName,
    totalPublications: get(
      response,
      'data.aggregations.totalPublications.value',
      0
    ),
    totalHits: get(response, 'data.hits.total.value', 0),
    sentencesList: {
      rows: parsedSentences,
      stems: stemsArr,
      quotes: quotesArr,
      highlightRules: {
        stems: quotesArr.length !== 0,
        quotes: false,
      },
    },
  };
};

export default {
  parseInitSearchResponse,
  parseRemoteConfigResponse,
  parseParagraphsResponse,
  parseHitsResponse,
  parseSingleHitResponse,
  parseNavigationResponse,
  parseInitialFilters,
  parseTitlesResponse,

  parseParagraphsToMap,
  parseParagraphsToMapWithoutParaId,
  parseFoundPublicationIdsResponse,
};
