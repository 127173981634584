import { reactive } from 'vue';
import utils from '@/utils/utils';
import { TITLE_KEY_SEPARATOR } from '@/constants/constants';

const COLLECTION_NORMALIZED_PREFIX = 'collection';
const COLLECTION_LABEL_PREFIX = 'Collection:';

class FilterTreeNode {
  constructor(data = {}, parent = null) {
    this.id = data.id || utils.generateShortUuid();
    this.key = data.key || null;
    this.lang = data.lang || '';

    this.name = data.name || '';
    this.nameReNormalized = data.nameReNormalized || '';
    this.originalName = data.originalName || '';

    this.parent = parent;
    this.children = new Map();

    this.filterText = data.filterText || '';
    this.filterPattern = data.filterPattern || '';

    this.checked = data.checked || false;
    this.hasChecked = data.hasChecked || false;

    this.filtered = data.filtered || false;
    this.hasFiltered = data.hasFiltered || false;

    this.hasFilteredChecked = data.hasFilteredChecked || false;

    this.expanded = data.expanded || false;

    this.isCategoriesHidden = data.isCategoriesHidden || false;
    this.isFound = data.isFound || true;

    this.isAllChildrenSupplemental = data.isAllChildrenSupplemental || false;

    this.isComplete = false;
  }

  insertNodes(data) {
    for (const [key, value] of Object.entries(data)) {
      let childNode = this.children.get(key);
      if (!childNode) {
        childNode = new CategoryFilterTreeNode({}, this);
        this.children.set(key, reactive(childNode));
      }
      childNode.insertNodes({ [key]: value });
    }
    return this;
  }

  setLang(lang) {
    this.lang = lang;
    return this;
  }

  setIsComplete(isComplete) {
    this.isComplete = isComplete;
    return this;
  }

  removeEmptyChildren() {
    this.children.forEach(child => {
      child.removeEmptyChildren();
      if (child.children.size === 0) {
        this.children.delete(child.key);
      }
    });
    return this;
  }

  setFoundPublicationIds(foundPublicationIds) {
    this.isFound =
      this instanceof TitleFilterTreeNode
        ? !!foundPublicationIds[this.publicationId]
        : true;
    this.children?.forEach(child =>
      child.setFoundPublicationIds(foundPublicationIds)
    );
    return this;
  }

  setCategoriesHidden(isCategoriesHidden) {
    this.isCategoriesHidden = isCategoriesHidden;
    return this;
  }
  setExpanded(expanded) {
    this.expanded = expanded;
    return this;
  }

  setChecked(checked, directClick = true) {
    if (directClick) {
      this.checked = checked;
      this.children.forEach(child => child.setChecked(checked));
    } else {
      this.checked = Array.from(this.children.values()).every(
        child => child.checked
      );
      this.setHasChecked();
    }

    if (this.parent) {
      this.parent.setChecked(checked, false);
    }
    return this;
  }

  setFilterText(filterText, filterPattern = null) {
    this.filterText = filterText;
    this.filterPattern = filterPattern || this.createFilterPattern(filterText);
    this.children.forEach(child =>
      child.setFilterText(this.filterText, this.filterPattern)
    );

    this.setExpanded(this.filterText.length);

    this.setFiltered();
    this.setHasFiltered();
    return this;
  }

  createFilterPattern(filterText) {
    let filterPattern = [...filterText.trim()];

    if (filterPattern.length) {
      filterPattern = filterPattern
        .map((letter, i) => {
          if (i === filterPattern.length - 1) {
            return letter;
          }
          return letter === ' ' ? '\\**' : letter + '\\**';
        })
        .join('');
    }
    return filterPattern;
  }

  setHasChecked() {
    this.hasChecked = Array.from(this.children.values()).some(
      child => child.hasChecked || child.checked
    );
    return this;
  }

  setFiltered() {
    const filterText = this.filterText.split(' ').join('');
    const splitName = this.name.split(TITLE_KEY_SEPARATOR);
    const name = splitName[splitName.length - 1];
    this.filtered = name?.includes(filterText);
    return this;
  }

  setHasFiltered() {
    this.hasFiltered = Array.from(this.children.values()).some(
      child => child.filtered || child.hasFiltered
    );
    return this;
  }

  setHasFilteredChecked() {
    this.hasFilteredChecked = Array.from(this.children.values()).some(
      child => (child.filtered && child.checked) || child.hasFilteredChecked
    );
    return this;
  }

  get isVisible() {
    return true;
  }

  get label() {
    if (
      !this.filterPattern.length ||
      !this.filtered ||
      !this.filterText.length
    ) {
      return this.originalName;
    }

    const regex = RegExp(this.filterPattern, 'gi');
    let label = this.originalName;
    let match;

    while ((match = regex.exec(this.nameReNormalized))) {
      const startPosition = match.index;
      const endPosition = regex.lastIndex;

      label =
        this.originalName.substring(0, startPosition) +
        '<span class="filter-req">' +
        this.originalName.substring(startPosition, endPosition) +
        '</span>' +
        this.originalName.substring(endPosition);
    }
    return label;
  }

  get visibleChildren() {
    return Array.from(this.children.values()).filter(child => child.isVisible);
  }

  get categories() {
    return Array.from(this.children.values()).filter(
      child => child.level === 0 && child.isVisible
    );
  }

  get checkedCount() {
    return Array.from(this.children.values()).reduce((acc, child) => {
      acc += child.checkedCount;
      return acc;
    }, 0);
  }

  get filteredPublicationIds() {
    return Array.from(this.children.values()).reduce((acc, child) => {
      acc = [...acc, ...child.filteredPublicationIds];
      return acc;
    }, []);
  }

  getNormalizedFilterText(filterText = '', lang) {
    filterText = filterText.toLowerCase().replace(/^\s*(a|an|the)\s+/gi, '');
    return _getReNormalizedName(filterText, lang, '');
  }
}

class CategoryFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 0;
    this.lang = this.parent?.lang || '';
    this.isCategoriesHidden = this.parent?.isCategoriesHidden || false;
  }

  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || key;
    this.originalName = this.originalName || value?.info?.label;
    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    for (const val of value.data) {
      const composedKey = val.info?.isCollection
        ? val.info?.collectionTitleNormalized
        : val.info?.labelNormalized;

      let childNode = this.children.get(composedKey);
      if (!childNode) {
        childNode = new AuthorFilterTreeNode({ key: composedKey }, this);
        this.children.set(composedKey, reactive(childNode));
      }
      childNode.insertNodes({ [composedKey]: val });
    }
    return this;
  }

  get isVisible() {
    return (
      !this.filterText.length ||
      this.hasFiltered ||
      Array.from(this.children.values()).some(item => item.hasFiltered)
    );
  }

  get checkboxDisabled() {
    return (
      !!this.filterText.length ||
      Array.from(this.children.values()).some(child => child.checkboxDisabled)
    );
  }

  get label() {
    return this.originalName;
  }
}

class AuthorFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 1;
    this.lang = this.parent?.lang || '';
    this.isCategoriesHidden = this.parent?.isCategoriesHidden || false;
    this.isAllChildrenSupplemental = true;
  }

  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || value.info?.labelNormalized;
    this.originalName = this.originalName || value?.info?.label;
    this.isCollection = this.isCollection || value?.info?.isCollection;

    if (this.isCollection) {
      this.setName(value.info?.collectionTitleNormalized);
      this.setOriginalName(value.info?.collectionTitle);
    }

    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    for (const val of value.data) {
      const composedKey = val.info.labelNormalized;
      let childNode = this.children.get(composedKey);
      if (!childNode) {
        childNode = new TitleFilterTreeNode({}, this);
        this.children.set(composedKey, reactive(childNode));
      }
      childNode.insertNodes({ [composedKey]: val });
      if (val.info.type === 'Book') {
        this.isAllChildrenSupplemental = false;
      }
      if (value.data.length === 1) {
        this.extraLabel = val.info.label;
      }
    }

    return this;
  }

  removeEmptyChildren() {}

  setName(extraNameNormalized) {
    if (extraNameNormalized) {
      this.name = COLLECTION_NORMALIZED_PREFIX + extraNameNormalized;
    }
  }

  setOriginalName(extraName) {
    if (extraName) {
      this.originalName = `<b>${COLLECTION_LABEL_PREFIX}</b> ${extraName}`;
    }
  }

  get childrenCount() {
    return this.children.size;
  }

  get checkedCount() {
    return Array.from(this.children.values()).filter(
      paragraph => paragraph.checked
    ).length;
  }

  get isVisible() {
    const isCategoryExpanded = this.isCategoriesHidden || this.parent?.expanded;

    if (this.filterText.length) {
      return isCategoryExpanded && (this.filtered || this.hasFiltered);
    }
    return isCategoryExpanded;
  }

  get filteredPublicationIds() {
    return Array.from(this.children.values()).reduce((acc, child) => {
      if (child.checked) {
        acc.push(child.publicationId);
      }
      return acc;
    }, []);
  }

  get checkboxDisabled() {
    return this.children.size === 0;
  }
}

class TitleFilterTreeNode extends FilterTreeNode {
  constructor(data = {}, parent = null) {
    super(data, parent);
    this.level = 2;
    this.publicationId = data.publicationId || null;
    this.type = data.type || null;
    this.lang = this.parent?.lang || '';
    this.collectionTitleNormalized = data.collectionTitleNormalized || null;
    this.isCollection = data.isCollection || false;
  }

  insertNodes(data) {
    const [key, value] = Object.entries(data)[0];
    this.key = this.key || key;
    this.name = this.name || key;
    this.originalName = this.originalName || value?.info?.label;
    this.publicationId = value.info.publicationId;
    this.type = value.info.bookType;
    this.collectionTitleNormalized = value.info.collectionTitleNormalized;
    this.isCollection = value.info.isCollection;

    if (this.isCollection) {
      this.setName(value.info?.authorNameNormalized);
    }

    this.nameReNormalized = _getReNormalizedName(this.originalName, this.lang);

    return this;
  }

  removeEmptyChildren() {}

  setName(authorNameNormalized) {
    if (authorNameNormalized) {
      this.name = `${this.name}${authorNameNormalized}`;
    }
  }

  get isVisible() {
    const isAuthorVisible = this.parent?.isVisible;
    const isAuthorExpanded = this.parent?.expanded;

    if (this.filterText.length) {
      return this.filtered && isAuthorVisible && isAuthorExpanded;
    }
    return isAuthorVisible && isAuthorExpanded;
  }
}

const normalizationMethodByLang = {
  default: 'NFD',
  ar: 'NFKC',
  fa: 'NFKC',
};

function isArabicLetter(char) {
  return /\p{Script=Arabic}/u.test(char);
}

function isLatinLetter(char) {
  return /\p{Script=Latin}/u.test(char);
}

function isCyrillicLetter(char) {
  return /\p{Script=Cyrillic}/u.test(char);
}

const checkMethodsByLang = {
  en: isLatinLetter,
  ar: isArabicLetter,
  ru: isCyrillicLetter,
  uk: isCyrillicLetter,
};

const _getReNormalizedName = (originalName, language, replaceSymbol = '*') => {
  let lang;
  const originalNameLetters = [...originalName];
  const normalizedOriginalName = originalNameLetters
    .map(letter => {
      let normalizedLetter;

      if (letter === ' ') {
        return letter;
      }

      for (lang in checkMethodsByLang) {
        if (!checkMethodsByLang[lang](letter)) {
          continue;
        }
        lang = lang === 'ar' && language === 'fa' ? 'fa' : lang;
        normalizedLetter = letter.normalize(
          normalizationMethodByLang[lang] || normalizationMethodByLang.default
        );
      }
      return normalizedLetter || letter;
    })
    .join('');
  return normalizedOriginalName
    .replace(/[\u0300-\u036f]/g, '')
    .replace(
      /(?:[^\u0600-\u06FF\u0750-\u077Fa-zA-Zа-яА-Я\r\n]|[\u064B-\u0652])/gim,
      replaceSymbol
    );
};

const createFilterTree = (data = {}, lang = '', isCategoriesHidden = false) => {
  return new FilterTreeNode()
    .setLang(lang)
    .setCategoriesHidden(isCategoriesHidden)
    .insertNodes(data, false);
};

export default {
  createFilterTree,
};
