var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],staticClass:"virtual-list-wrapper search-hits",class:{
    'scrolling-on': _vm.isScrolling,
    'hide-virtualList-header': _vm.hideVirtualListHeader,
  },on:{"click":function($event){return _vm.setFocus()},"copy":_vm.handleCopyEvent}},[_c('virtual-list',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus.hits),expression:"focus.hits"},{name:"show",rawName:"v-show",value:(_vm.isHitsVisible),expression:"isHitsVisible"}],ref:"virtualList",staticClass:"virtual-list",attrs:{"data-sources":_vm.hits,"data-key":'id',"data-component":_vm.searchHitScrollItem,"extra-props":{
      activeHitIndex: _vm.activeHitIndex,
      hitItemEvent: _vm.handleHitItemEvent,
    },"keeps":50,"remain":50,"start":_vm.scrollStartIndex,"tabindex":"100","item-class":"list-item scroll-item","item-scoped-slots":_vm.$scopedSlots},on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"fake-header",attrs:{"slot":"header"},slot:"header"},[(_vm.expandedPublicationId)?_c('HitsGroupTopContainer',{attrs:{"source":_vm.expandedPublicationData},on:{"hitItemEvent":_vm.handleHitItemEvent}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }