<template>
  <div class="top-bar" :class="{ 'book-search-view': searchInsideBook }">
    <div v-hotkey="keymap" class="search-criteria-block">
      <div
        class="progress-bar-slider"
        :class="{ 'progress-bg-active': searchInProcess }"
      ></div>

      <div
        class="search-field-container"
        :class="{ '-focused': fieldFocused, '-dirty': isDirty }"
      >
        <span class="search-element logo-block">
          <BaseIcon
            :icon-name="logoIcon.iconName"
            :viewBox="logoIcon.iconViewBox"
            :width="logoIcon.iconWidth"
            :height="logoIcon.iconHeight"
          />
        </span>

        <SearchField
          ref="searchField"
          @searchFieldEvent="searchFieldEventHandler"
        />

        <BaseButton
          v-show="shouldShowToolbar || searchInsideBook"
          data-test="clear-button"
          class="search-element -clear-icon"
          :title="$tc('Tooltips.clearButton')"
          :disabled="!getIsOnline"
          @click="clearSearchResults"
        >
          <span class="icon-container">
            <BaseIcon icon-name="ico-clear" />
          </span>
        </BaseButton>
      </div>

      <BaseButton
        v-if="isKeyboardBtnVisible"
        data-test="keyboard-icon"
        class="search-element -keyboard-icon"
        :class="{ disabled: !isOnline }"
        :disabled="!isOnline"
        :title="$tc('Tooltips.keyboardButton')"
        @click="toggleKeyboard"
      >
        <span class="icon-container">
          <BaseIcon icon-name="ico-keyboard" />
        </span>
      </BaseButton>

      <BaseButton
        v-show="!searchInsideBook"
        :disabled="menuOpened"
        data-test="dropdown-icon"
        class="search-element -dropdown-icon -narrow"
        @click="toggleMenu"
        :title="$tc('Tooltips.changeLanguageButton')"
      >
        <span class="icon-container collapsed-menu">
          <BaseIcon icon-name="ico-hidden-menu" />
        </span>
        <span class="detailed-menu">
          <span>{{ $tc('Languages.' + lang) }}</span>
          <span class="icon-container">
            <BaseIcon icon-name="ico-arrow-chevron" />
          </span>
        </span>
      </BaseButton>

      <BaseTooltip
        v-if="menuOpened"
        :h-alignment="'right'"
        :v-alignment="'bottom'"
        @baseTooltipEvent="baseTooltipEventHandler"
      >
        <Languages v-show="shouldShowMenuButton" />
        <BaseButton
          class="-tooltip-icon"
          @click="toggleInfoApp"
          data-test="dropdown-icon-in-dropdown"
        >
          <span class="icon-container info-block">
            <BaseIcon icon-name="ico-info-app" />
          </span>
          <span class="button-text">{{ $tc('SearchTips') }}</span>
        </BaseButton>
      </BaseTooltip>

      <BaseButton
        v-if="possibleTempHiddenBlock"
        data-test="tooltip-icon"
        class="search-element -tooltip-icon"
        @click="toggleInfoApp"
        :title="$tc('Tooltips.infoButton')"
      >
        <span class="icon-container">
          <BaseIcon icon-name="ico-info-app" />
        </span>
      </BaseButton>
    </div>
    <div v-if="!(searchInsideBook || pageHeader)" class="top-info">
      <div class="info-text-block">
        <div v-if="possibleTempHiddenBlock" class="results-info">
          {{
            $t('TopBar.BooksMatchesWereFound.label', {
              booksTotal: totalPublications,
              sentencesTotal: totalHits,
            })
          }}
        </div>
        <div class="tips-slider">
          <div class="ico-block"><BaseIcon icon-name="ico-lamp" /></div>
          <div class="slider-item-wrapper">
            <div
              v-for="(tip, index) in tips"
              :key="index"
              class="tips-slider-item"
              :class="'-item' + index"
              v-html="tip.text"
            ></div>
          </div>
        </div>
      </div>
      <div class="info-control-block">
        <div class="toggle-info-button" @click="toggleInfoApp">
          <span v-if="infoOpened" class="button-label">
            Hide tips
          </span>
          <span v-else class="button-label">
            Show tips
          </span>
          <span class="icon-container" :class="{ '-expanded': infoOpened }">
            <BaseIcon icon-name="ico-arrow-chevron" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import debounce from 'lodash/debounce';

  import defaultConfig from '@/configs/default.config.json';
  import { FOCUS_SELECTOR_SEARCH_FIELD } from '@/constants/constants';

  import BaseButton from '@/components/BaseButton.vue';
  import BaseIcon from '@/components/BaseIcon.vue';
  import BaseTooltip from '@/components/BaseTooltip.vue';

  import EventEnum from '@/enums/EventEnum';

  import SearchField from '@/containers/searchfield/SearchField.vue';
  import Languages from '@/containers/languages/Languages.vue';

  import layoutUtils from '@/utils/layoutUtils';

  import * as log from 'loglevel';
  log.setLevel('info');

  const SET_MENU_OPENED_DEBOUNCE = 100;

  export default {
    name: 'TopBar',
    components: {
      SearchField,
      BaseButton,
      BaseTooltip,
      Languages,
      BaseIcon,
    },
    props: {
      searchInsideBook: {
        type: Boolean,
        default: false,
      },
      pageHeader: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        brands: defaultConfig.brands,
        fieldFocused: null,
        searchField: null,
        tips: [
          {
            text: this.$tc('TopBar.Tips.FirstTip.label'),
          },
          {
            text: this.$tc('TopBar.Tips.SecondTip.label'),
          },
          {
            text: this.$tc('TopBar.Tips.ThirdTip.label'),
          },
        ],
        possibleTempHiddenBlock: false,
      };
    },
    computed: {
      ...mapState('SwSearchStore', [
        'searchText',
        'searchInProcess',
        'totalPublications',
        'totalHits',
        'foundNothing',
      ]),
      ...mapState('SwFilterStore', ['filterOpened']),
      ...mapState('SwOfflineModeStore', ['isOnline']),
      ...mapState('SwContextStore', [
        'brand',
        'lang',
        'menuOpened',
        'keyboardOpened',
        'infoOpened',
        'mainPopupOpened',
      ]),
      ...mapGetters('SwOfflineModeStore', ['getIsOnline']),

      isFilterEnabled() {
        return this.$store.getters['SwFilterStore/isFilterEnabled'];
      },

      keymap() {
        if (!this.mainPopupOpened) {
          return false;
        }

        return {
          'ctrl+i': e => {
            e.preventDefault();
            this.toggleInfoApp();
          },
        };
      },
      shouldShowToolbar() {
        return (
          !this.searchInsideBook &&
          !this.searchInProcess &&
          (this.foundNothing || this.totalHits > 0 || !!this.searchText)
        );
      },
      logoIcon() {
        return {
          iconName: 'ico-search',
          iconViewBox: '0 0 24 24',
          iconWidth: 24,
          iconHeight: 24,
        };
      },
      shouldShowMenuButton() {
        return (
          this.brand === defaultConfig.brands.ocean ||
          this.brand === defaultConfig.brands.ool
        );
      },
      isDirty() {
        return this.searchText;
      },
      isKeyboardBtnVisible() {
        const isMobile = layoutUtils.isMobile();
        return (
          (!isMobile &&
            this.brand === defaultConfig.brands.ocean &&
            this.lang !== defaultConfig.defaultLanguages.en) ||
          (this.brand === defaultConfig.brands.ool &&
            this.lang !== defaultConfig.defaultLanguages.en)
        );
      },
    },
    mounted() {
      this.fieldFocused = this.$refs.searchField.focus.searchField;
      const self = this;
      this.$el.parentNode.addEventListener(
        'touchstart',
        event => {
          if (
            self.$refs.searchField &&
            !self.$refs.searchField.$el.contains(event.target)
          ) {
            self.$refs.searchField.focus.searchField = false;
          }
        },
        { passive: true }
      );
      this.$store.commit('SwContextStore/setTopBarInitiated', true);
    },
    methods: {
      clearSearchResults() {
        this.$store.dispatch('SwSearchStore/totalReset');
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_SELECTOR_SEARCH_FIELD,
        });
      },

      toggleMenu(event) {
        if (event) {
          event.preventDefault();
          event.cancelBubble = true;
        }
        this.setMenuOpened(!this.menuOpened);
      },

      baseTooltipEventHandler(event) {
        switch (event.type) {
          case 'clickOutside':
            this.setMenuOpened(false);
            document.querySelector('#search-input')?.focus();
            break;
          default:
            break;
        }
      },

      toggleKeyboard() {
        this.$store.dispatch(
          'SwContextStore/setKeyboardOpened',
          !this.keyboardOpened
        );
      },

      setMenuOpened: debounce(function(isOpened) {
        this.$store.dispatch('SwContextStore/setMenuOpened', isOpened);
      }, SET_MENU_OPENED_DEBOUNCE),

      toggleInfoApp() {
        this.$store.dispatch('SwContextStore/setInfoOpened', !this.infoOpened);
      },

      getLocalizedNumber(number) {
        return this.$store.getters['SwContextStore/getLocalizedNumber'](number);
      },

      $_checkSearchText(queryInputtedForFilterButton, filterActiveCharsNum) {
        return (
          !this.foundNothing &&
          queryInputtedForFilterButton?.trim().length >= filterActiveCharsNum
        );
      },

      searchFieldEventHandler(event) {
        switch (event.type) {
          case EventEnum.ON_FOCUS:
            this.fieldFocused = true;
            break;
          case EventEnum.ON_BLUR:
            this.fieldFocused = false;
            break;
          default:
            break;
        }
      },
    },
  };
</script>

<style src="./TopBar.less" lang="less"></style>
