import FilterFactory from '@/classes/factories/FilterFactory';
import FullTextSearchFactory from '@/classes/factories/queries/FullTextSearchFactory';

import FullTextSearchService from '@/services/Search/FullTextSearchService';
import ParseResponse from '@/services/ElasticSearch/ParseResponse';
import FilteringService from '@/services/ElasticSearch/FilteringService';

import localStorageService from '@/services/LocalStorageService';
import LocalStorageKeyEnum from '@/enums/LocalStorageKeyEnum';

import filterConfig from '@/configs/filterConfig.js';
import config from '@/configs/default.config.json';

import languageUtils from '@/utils/languageUtils';

import * as log from 'loglevel';
log.setLevel('info');

const DEFAULT_FILTER_TAGS = [];
let afterReloadFoundFilteredPublicationIds = {};

const state = _createInitialFilterState();

function _createInitialFilterState() {
  return {
    filterReady: false,
    filterBuilding: false,
    filterResponse: null,
    filterTags: DEFAULT_FILTER_TAGS,
    filterByLang: config.languages.reduce((filter, lang) => {
      filter[lang] = FilterFactory.createFilterTree();
      return filter;
    }, {}),
    filter: FilterFactory.createFilterTree(),
    userFilter: FilterFactory.createFilterTree(),
  };
}

const getters = {
  getFilter: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['SwContextStore/getLang'];
    return state.filterByLang[lang];
  },
  getFilterBuilding: state => {
    return state.filterBuilding;
  },
  getFilterResponse: state => {
    return state.filterResponse;
  },
  getCategories: (state, getters, rootState, rootGetters) => {
    const lang = rootGetters['SwContextStore/getLang'];
    return state.filterByLang[lang].categories;
  },
  getSelectedCategories: (state, getters) => {
    return getters.getCategories.reduce((categories, category) => {
      if (category.checked) {
        categories.push(category);
      }
      return categories;
    }, []);
  },
  getFilterPinnedAuthorsNormalized: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    const lang = rootGetters['SwContextStore/getLang'];
    const brand = rootGetters['SwContextStore/getBrand'];
    const authors = filterConfig[brand]?.[lang]?.pinnedAuthors || [];
    const authorsNormalized = authors.map(author =>
      languageUtils.normalize(author, lang)
    );
    return authorsNormalized;
  },
  getFilterReady: state => {
    return state.filterReady;
  },
};

const actions = {
  fillInitialFilterStore({ commit, rootGetters }, payload) {
    const lang = rootGetters['SwContextStore/getLang'];
    const shouldShowFilterCategories =
      rootGetters['SwContextStore/shouldShowFilterCategories'];
    commit('setFilterResponse', payload);
    commit('setInitialFilter', {
      lang,
      filter: payload,
      isCategoriesHidden: !shouldShowFilterCategories,
    });
    commit('setInitialFilterTags');
  },

  resetFilterStore({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterResponse');
    commit('setInitialFilter', { lang });
    commit('setInitialFilterTags');
  },

  resetUserFilter({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];

    commit('resetUserFilter', lang);
    commit('setInitialFilterTags');
  },

  async performFilterSearchIfNeeded({
    dispatch,
    getters,
    commit,
    rootGetters,
  }) {
    try {
      const esInfoMap = rootGetters['SwContextStore/getEsInfoMap'];
      if (!esInfoMap) {
        log.info('performFilterSearchIfNeeded: esInfoMap is not ready');
        return;
      }

      // This prevents multiple search requests for filters
      if (getters.getFilterBuilding) {
        return;
      }

      const filter = getters.getFilter;
      if (filter?.isComplete) {
        return;
      }

      commit('setFilterBuilding', true);
      commit('setFilterReady', false);

      // This is the new (2024) implementation of pinned authors - see filterConfig
      const brand = rootGetters['SwContextStore/getBrand'];
      const lang = rootGetters['SwContextStore/getLang'];
      const initialFilter = filterConfig.initialFilters[brand][lang];

      // Process the complete data
      try {
        dispatch('fillInitialFilterStore', initialFilter);
      } catch (e) {
        log.error(e);
      }

      // Single request to get all filter data
      const completeFilterData = await dispatch('getFilter');
      // console.log(completeFilterData);

      // // USE THIS TO EXPORT THE INITIAL FILTER DATA BASED ON PINNED AUTHORS
      // const pinnedAuthors = getters.getFilterPinnedAuthorsNormalized;
      // const exportedConfig = {};
      // for (const key of Object.keys(completeFilterData)) {
      //   if (!exportedConfig[key]) {
      //     exportedConfig[key] = {
      //       info: { ...completeFilterData[key].info },
      //       data: [],
      //     };
      //   }
      //   for (const author of pinnedAuthors) {
      //     const authorRecord = completeFilterData[key].data.find(obj => {
      //       return obj.info.labelNormalized === author;
      //     });
      //     if (authorRecord) {
      //       exportedConfig[key].data.push({
      //         [author]: {
      //           info: { ...authorRecord.info },
      //           data: [],
      //         },
      //       });
      //     }
      //   }
      // }
      // console.log(exportedConfig);

      // Process the complete data
      try {
        const filter = getters.getFilter;
        filter.insertNodes(completeFilterData);
        filter.removeEmptyChildren();
        commit('setIsComplete', { filterItem: filter, isComplete: true });
      } catch (e) {
        log.error(e);
      }

      commit('setFilterReady', true);
      commit('setFilterBuilding', false);

      if (afterReloadFoundFilteredPublicationIds) {
        commit('setFoundFilteredPublicationIds', {
          lang,
          publicationIds: afterReloadFoundFilteredPublicationIds,
        });
        afterReloadFoundFilteredPublicationIds = null;
      }
    } catch (error) {
      log.info(`performFilterSearch failed with ${error}`);
      throw error;
    }
  },
  /**
   * @returns {Promise<{
   *  categories: {total: *, list: *},
   *  publications: {total: *, list: *},
   *  authors: {total: *, list: *}}
   * >}
   */
  getFilter({ rootGetters }) {
    try {
      const options = {
        language: rootGetters['SwContextStore/getLang'],
      };
      return getFilter(options);
    } catch (error) {
      log.info(`getFilter failed with ${error}`);
      throw error;
    }
  },

  getLastUserFilter({ commit, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    const lastUserFilter = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
    commit('setInitialFilter', { lang, filter: lastUserFilter });
  },

  getFilteredPublicationIds({ state, rootGetters }) {
    const lang = rootGetters['SwContextStore/getLang'];
    return state.filterByLang[lang]?.filteredPublicationIds || [];
  },

  setLastUserFilter({ state }) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER,
      state.filter
    );
  },

  removeLastUserFilter() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_USER_FILTER
    );
  },

  async performToggleCheckedAndSearch(
    { commit, dispatch, state },
    { lang, categories = [] }
  ) {
    if (!state.filterByLang[lang]?.visibleChildren?.length) {
      return;
    }
    state.filterByLang[lang].visibleChildren.forEach((c, i) => {
      const visibleChildrenItem = state.filterByLang[lang].visibleChildren[i];
      const isCategoryChecked = categories.includes(c.name);
      if (
        visibleChildrenItem.hasChecked &&
        !visibleChildrenItem.checked &&
        !isCategoryChecked
      ) {
        return;
      }
      commit('setChecked', {
        filterItem: visibleChildrenItem,
        checked: isCategoryChecked,
      });
    });
    await dispatch(
      'SwSearchStore/performSearch',
      {
        fromFilterReq: true,
      },
      { root: true }
    );
  },

  toggleExpanded({ commit }, filterItem) {
    commit('setExpanded', { filterItem, expanded: !filterItem.expanded });
  },

  toggleChecked({ commit }, filterItem) {
    commit('setChecked', { filterItem, checked: !filterItem.checked });
  },

  setFilterText({ commit, dispatch, rootGetters }, filterText) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterText', { lang, filterText });

    dispatch('setLastFilterText');
  },

  setLastFilterText({ state }, filterText) {
    localStorageService.setDataIntoLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT,
      filterText || state.filterText
    );
  },

  getLastFilterText({ commit, rootGetters }) {
    const lastFilterText = localStorageService.getDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT
    );
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFilterText', { lang, lastFilterText });
  },

  removeLastFilterText() {
    localStorageService.removeDataFromLocalStorage(
      LocalStorageKeyEnum.SEARCH_FILTER_TEXT
    );
  },

  resetFoundFilteredPublicationIds({ rootGetters, commit }) {
    const lang = rootGetters['SwContextStore/getLang'];
    commit('setFoundFilteredPublicationIds', { lang });
  },

  async getFoundFilteredPublicationIds({ commit, rootGetters, getters }) {
    const language = rootGetters['SwContextStore/getLang'];
    const parsedQuery = JSON.parse(
      JSON.stringify(rootGetters['SwSearchStore/getParsedQuery'])
    );
    parsedQuery.filter = [];
    const publicationIds = await getFoundPublicationIds({
      language,
      parsedQuery,
    });

    const isFilterReady = getters.getFilterReady;
    if (!isFilterReady) {
      afterReloadFoundFilteredPublicationIds = publicationIds;
      return;
    }

    commit('setFoundFilteredPublicationIds', {
      lang: language,
      publicationIds,
    });
  },
};

const mutations = {
  setFilterBuilding(state, filterBuilding) {
    state.filterBuilding = filterBuilding;
  },

  setFilterResponse(state, filterResponse) {
    state.filterResponse = filterResponse;
  },

  setInitialFilter(state, { lang, filter, isCategoriesHidden }) {
    if (!filter) {
      return;
    }
    state.filterByLang[lang] = FilterFactory.createFilterTree(
      filter,
      lang,
      isCategoriesHidden
    );
  },

  resetUserFilter(state, lang) {
    state.filterByLang[lang].setChecked(false);
    state.filterByLang[lang].setFilterText('');
  },

  setInitialFilterTags(state) {
    state.filterTags = [];
  },

  addFilterTag(state, filterTag) {
    FilteringService.parseTagAdd({
      filter: state.filter,
      tags: state.filterTags,
      addedTag: filterTag,
    });
  },

  removeFilterTag(state, filterTag) {
    FilteringService.parseTagRemove({
      filter: state.filter,
      tags: state.filterTags,
      removedTag: filterTag,
    });
  },

  setFilterReady(state, filterReady) {
    state.filterReady = filterReady;
  },

  setCategoriesExpanded(state, { lang, expanded }) {
    state.filterByLang[lang]?.visibleChildren?.forEach(category => {
      category.setExpanded(expanded);
    });
  },

  setExpanded(state, { filterItem, expanded }) {
    filterItem.setExpanded(expanded);
  },

  setChecked(state, { filterItem, checked }) {
    filterItem.setChecked(checked);
  },

  setIsComplete(state, { filterItem, isComplete }) {
    filterItem.setIsComplete(isComplete);
  },

  resetFilterByLangObject(state, { lang }) {
    const filter = Object.create(state.filterByLang[lang]);
    state.filterByLang[lang] = filter;
  },

  setFilterText(state, { lang, filterText }) {
    const normalizedFilterText = state.filterByLang[
      lang
    ].getNormalizedFilterText(filterText, lang);
    state.filterByLang[lang].setFilterText(normalizedFilterText);
  },

  setFoundFilteredPublicationIds(state, { lang, publicationIds = {} }) {
    state.filterByLang[lang].setFoundPublicationIds(publicationIds);
  },
};

async function getFilter(options) {
  try {
    const response = await FullTextSearchService.getFilter(options);
    const parsed = ParseResponse.parseInitialFilters(response.data);
    return parsed;
  } catch (e) {
    log.info(`getFilter failed with ${e}`);
    return {};
  }
}

async function getFoundPublicationIds(options) {
  try {
    const query = FullTextSearchFactory.createFoundPublicationIdsQueryParams(
      options
    );
    const response = await FullTextSearchService.getFoundPublicationIds(query);
    return ParseResponse.parseFoundPublicationIdsResponse({
      response,
      lang: options.language,
    });
  } catch (e) {
    log.info(`getFoundPublicationIds failed with ${e}`);
    return ParseResponse.parseFoundPublicationIdsResponse({
      response: {},
    });
  }
}

export default {
  name: 'SwFilterStore',
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
