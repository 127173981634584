<template>
  <div
    class="advanced-filter"
    :class="{ '-no-categories': !shouldShowFilterCategories }"
  >
    <div class="filter-field-block">
      <input
        v-model="inputValue"
        v-focus="focus.filterField"
        placeholder="Find author or title"
        :disabled="!(filterReady && getIsOnline)"
        @focus="changeFocus"
      />
      <div v-if="inputValue.length" class="clear-filter" @click="clearInput">
        <BaseIcon icon-name="ico-clear" />
      </div>
    </div>
    <div class="filter-info-block" @click="setFocusToBlock">
      <div>
        Filtered documents:
        <span>{{ filterReady ? filter.checkedCount : 0 }}</span>
      </div>
      <BaseButton
        @click="resetFilter"
        :disabled="
          !getIsOnline ||
            !(filter.hasChecked || filter.filterText || inputValue.length)
        "
        >Clear all</BaseButton
      >
    </div>
    <div
      v-if="!isFilterApplied || filter.hasFiltered"
      v-focus="focus.filterBlock"
      class="filter-tree-block"
      :class="{
        'filter-applied': isFilterApplied,
      }"
      @click="setFocusToBlock"
    >
      <div
        v-for="category in filter.visibleChildren"
        :key="category.id"
        class="filter-first-level -filter-item"
        :class="{
          expanded: category.expanded,
          '-no-category-items': filter.filterText && !category.hasFiltered,
          '-has-checked': category.hasChecked,
        }"
      >
        <SearchFilterItem
          :filter-item="category"
          v-if="shouldShowFilterCategories"
          v-show="category.isVisible"
          @filterItemEvent="handleFilterItemEvent"
        />
        <div
          v-for="author in category.visibleChildren"
          :key="author.id"
          class="filter-second-level -filter-item"
          :class="{
            expanded: author.expanded,
            '-has-checked': author.hasChecked,
          }"
        >
          <SearchFilterItem
            :filter-item="author"
            :extra-label="getExtraLabelIfNeeded(author)"
            v-show="author.isVisible"
            @filterItemEvent="handleFilterItemEvent"
          />
          <section v-if="shouldShowNestedItems(author)">
            <div
              v-for="title in author.visibleChildren"
              :key="title.id"
              class="filter-third-level -filter-item"
            >
              <SearchFilterItem
                :filter-item="title"
                v-show="title.isVisible"
                @filterItemEvent="handleFilterItemEvent"
              />
            </div>
          </section>
        </div>
      </div>
    </div>
    <div v-else class="filter-no-results">
      <BaseIcon icon-name="pic-no-results" />
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce';
  import { mapState, mapGetters } from 'vuex';
  import { focus } from 'vue-focus';
  import {
    FOCUS_FILTER_FIELD,
    FOCUS_FILTER_BLOCK,
  } from '@/constants/constants';
  import SearchFilterItem from '@/containers/searchfilter/SearchFilterItem.vue';
  import BaseButton from '@/components/BaseButton.vue';
  import BaseIcon from '@/components/BaseIcon.vue';
  import EventEnum from '@/enums/EventEnum';

  import * as log from 'loglevel';
  log.setLevel('info');

  const INPUT_DEBOUNCE_TIME = 1000;

  export default {
    name: 'SearchFilter',
    components: {
      BaseButton,
      SearchFilterItem,
      BaseIcon,
    },
    directives: {
      focus: focus,
    },
    data() {
      return {
        observer: null,
        currentScrollPosition: 0,
        isScrollingDown: false,
      };
    },
    computed: {
      ...mapState('SwFilterStore', ['filterReady']),
      ...mapState('SwContextStore', ['focus', 'mainPopupOpened']),
      ...mapGetters('SwKeyboardStore', ['getInput']),
      ...mapGetters('SwOfflineModeStore', ['getIsOnline']),

      shouldShowFilterCategories() {
        return this.$store.getters['SwContextStore/shouldShowFilterCategories'];
      },

      filter() {
        return this.$store.getters['SwFilterStore/getFilter'];
      },

      language() {
        return this.$store.getters['SwContextStore/getLang'];
      },

      inputValue: {
        get() {
          return this.getInput(FOCUS_FILTER_FIELD);
        },
        set(val) {
          this.$store.commit('SwKeyboardStore/setKeyboardInput', {
            field: FOCUS_FILTER_FIELD,
            input: val,
          });
        },
      },

      isFilterApplied() {
        return !!this.inputValue.length;
      },
    },
    watch: {
      inputValue() {
        this.debounceInput();
      },

      language() {
        this.inputValue = this.filter?.filterText || '';
      },

      /**
       * When search widget will have only page representation,
       * this watcher should be removed
       */
      mainPopupOpened(isMainPopupOpened) {
        if (isMainPopupOpened && this.inputValue?.length) {
          this.debounceInput();
        }
      },
    },
    mounted() {
      this.observer = new IntersectionObserver(this.handleIntersection, {
        root: document.querySelector('.filter-tree-block'),
        rootMargin: '500px 0px',
        threshold: 0,
      });

      if (this.inputValue?.length) {
        this.debounceInput();
      }
    },
    methods: {
      handleFilterItemEvent({ type, element }) {
        if (!this.filterReady) {
          return;
        }
        switch (type) {
          case EventEnum.FILTER_ITEM_MOUNTED:
            this.observer?.observe(element);
            break;
          case EventEnum.FILTER_ITEM_BEFORE_DESTROY:
            this.observer?.unobserve(element);
            break;
          default:
            log.info(
              `Unknown event type ${type} provided in handleFilterItemEvent`
            );
            break;
        }
      },
      handleIntersection(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            delete entry.target.parentNode.dataset.hidden;
          } else {
            entry.target.parentNode.dataset.hidden = '';
          }
        });
      },
      shouldShowNestedItems(item) {
        return item.children.size > 1;
      },
      getExtraLabelIfNeeded(item) {
        if (!this.shouldShowNestedItems(item)) {
          const firstChild =
            item.children && item.children.size > 0
              ? Array.from(item.children.values())[0]
              : null;
          return firstChild?.label;
        }
      },
      resetFilter() {
        this.$store.dispatch('SwFilterStore/resetUserFilter');
        this.inputValue = '';

        this.$store.dispatch('SwSearchStore/performSearch');
      },
      debounceInput: debounce(function() {
        this.$store.dispatch('SwFilterStore/setFilterText', this.inputValue);
      }, INPUT_DEBOUNCE_TIME),
      clearInput() {
        this.inputValue = '';
        this.$store.dispatch('SwFilterStore/setFilterText', this.inputValue);
        this.changeFocus();
      },
      changeFocus() {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_FILTER_FIELD,
        });
      },
      setFocusToBlock() {
        this.$store.dispatch('SwContextStore/changeFocus', {
          selector: FOCUS_FILTER_BLOCK,
        });
      },
    },
  };
</script>

<style lang="less" src="./SearchFilter.less"></style>
