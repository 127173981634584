export function expandFilters(condensed) {
  const expanded = {};

  for (const [key, value] of Object.entries(condensed)) {
    expanded[key] = {
      info: { label: typeof value === 'string' ? value : value.label },
      data:
        typeof value === 'string'
          ? []
          : [
              ...Object.entries(value.authors || {}).map(
                ([normalized, label]) => ({
                  info: {
                    label,
                    labelNormalized: normalized,
                  },
                  data: [],
                })
              ),
              ...Object.entries(value.collections || {}).map(
                ([normalized, label]) => ({
                  info: {
                    label,
                    labelNormalized: normalized,
                    collectionTitle: label,
                    collectionTitleNormalized: normalized,
                    isCollection: true,
                  },
                  data: [],
                })
              ),
            ],
    };
  }
  return expanded;
}

export default {
  ffa: {
    en: {
      pinnedAuthors: [],
    },
  },
  ocean: {
    en: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'matthew',
        'mark',
        'luke',
        'john',
        'muhammad',
      ],
    },
    ar: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرةبهآءالله',
        'حضرة الباب',
        'حضرةعبدالبهاء',
        'حضرة شوقي أفندي',
        'حضرة شوقي أفندي',
        'بيت العدل الاعظم',
        'محمد',
      ],
    },
    fa: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرتبهاءالله',
        'حضرة بهآء الله',
        'حضرت باب',
        'حضرةعبدالبهاء',
        'حضرت عبدالبهاء',
        'حضرت شوقى افندى',
        'بيت العدل اعظم\u200E\u200E',
        'نبيل زرندى',
        'محمد',
      ],
    },
  },
  ool: {
    en: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
      ],
    },
    ar: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرةبهآءالله',
        'حضرة الباب',
        'حضرةعبدالبهاء',
        'حضرة شوقي أفندي',
        'بيت العدل الاعظم',
        'محمد',
      ],
    },
    fa: {
      pinnedAuthors: [
        'bahaullah',
        'bab',
        'abdulbaha',
        'shoghieffendi',
        'universalhouseofjustice',
        'nabil',
        'حضرتبهاءالله',
        'حضرة بهآء الله',
        'حضرت باب',
        'حضرةعبدالبهاء',
        'حضرت شوقى افندى',
        'بيت العدل اعظم\u200E\u200E',
        'نبيل زرندى',
        'محمد',
      ],
    },
  },
  initialFilters: {
    // FFA has no pinned authors, but the genres should show immediately
    ffa: expandFilters({
      children: `Children`,
      drama: `Drama`,
      history: `History`,
      ideas: `Ideas`,
      novels: `Novels`,
      religion: `Religion`,
      science: `Science`,
      shortstories: `Short Stories`,
      verse: `Verse`,
    }),

    // Ocean has categories which should show immediately, and some pinned authors
    ocean: {
      en: expandFilters({
        bahai: {
          label: `Bahá’í`,
          authors: {
            bahaullah: `Bahá’u’lláh`,
            bab: `The Báb`,
            abdulbaha: `‘Abdu’l-Bahá`,
            shoghieffendi: `Shoghi Effendi`,
            universalhouseofjustice: `Universal House of Justice`,
            nabilzarandi: `Nabil Zarandi`,
          },
        },
        buddhist: `Buddhist`,
        christian: {
          label: `Christian`,
          collections: {
            newtestamentkjv: `New Testament, KJV`,
            pentateuchkjv: `Pentateuch, KJV`,
            prophetskjv: `Prophets, KJV`,
            writingskjv: `Writings, KJV`,
          },
        },
        confucian: `Confucian`,
        hindu: 'Hindu',
        islam: {
          label: `Islam`,
          authors: {
            muhammad: `Muhammad`,
            muhammadibnabdullah: `Muhammad ibn ‘Abdu‘lláh`,
          },
          collections: {
            quran: `The Qur’an`,
          },
        },
        jainism: `Jainism`,
        judaism: {
          label: `Judaism`,
          collections: {
            torahjps: `Torah, JPS`,
            neviimjps: `Nevi‘im, JPS`,
            ketuvimjps: `Ketuvim, JPS`,
          },
        },
        tao: `Tao`,
        zoroastrian: {
          label: `Zoroastrian`,
          authors: {
            zoroaster: `Zoroaster`,
          },
          collections: {
            avesta: `The Avesta`,
            khordaavesta: `The Khorda Avesta`,
          },
        },
      }),
      ar: expandFilters({
        bahai: {
          label: `Bahá’í`,
          authors: {
            حضرةبهآءالله: `حضرة بهآء الله`,
            حضرةعبدالبهاء: `حضرة عبد البهاء`,
          },
        },
      }),
      fa: expandFilters({
        bahai: {
          label: `Bahá’í`,
          authors: {
            حضرتبهاءالله: `حضرت بهاءالله`,
            حضرةبهآءالله: `حضرة بهآء الله`,
            حضرتباب: `حضرت باب`,
            حضرتعبدالبهاء: `حضرت عبدالبهاء`,
            نبيلزرندى: `نبيل زرندى`,
          },
        },
        بهائی: {
          label: `بهائی`,
          authors: {
            حضرتبهاءالله: `حضرت بهاءالله`,
            حضرتباب: `حضرت باب`,
            حضرتعبدالبهاء: `حضرت عبدالبهاء`,
          },
        },
      }),
    },

    // Ocean of Lights has some pinned authors, which can show immediately
    ool: {
      en: expandFilters({
        bahai: {
          label: `Bahá’í`,
          authors: {
            bahaullah: `Bahá’u’lláh`,
            bab: `The Báb`,
            abdulbaha: `‘Abdu’l-Bahá`,
            shoghieffendi: `Shoghi Effendi`,
            universalhouseofjustice: `Universal House of Justice`,
            nabilzarandi: `Nabil Zarandi`,
          },
        },
      }),
    },
  },
};
