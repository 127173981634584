<template>
  <div
    class="search-filter-item"
    v-on="$listeners"
    :class="{ 'not-found-publication': shouldGreyOut }"
  >
    <span
      class="icon-block icon-collapse"
      @click="toggleExpanded"
      :class="{ hidden: !shouldShowExpandedIcon }"
    >
      <BaseIcon icon-name="ico-arrow-solid" />
    </span>
    <div class="checkbox-block" @click="toggleChecked">
      <input
        type="checkbox"
        :checked="filterItem.checked"
        :disabled="filterItem.checkboxDisabled"
      />
      <span class="checkbox" :key="filterItem.checked"></span>
    </div>
    <span class="item-text">
      <span
        @click="toggleExpanded"
        v-html="getLabel(filterItem.label)"
        :class="{
          disabled: filterItem.checkboxDisabled,
        }"
        tabindex="-1"
        ref="focusMagnet"
        class="filter-item-row"
      ></span
      ><span v-if="shouldShowChildrenCount" class="item-count"
        >({{ filterItem.childrenCount }})</span
      >
    </span>
  </div>
</template>

<script>
  import BaseIcon from '@/components/BaseIcon.vue';
  import debounce from 'lodash/debounce';

  import EventEnum from '@/enums/EventEnum';
  import { mapState } from 'vuex';

  const PERFORM_SEARCH_DEBOUNCE = 300;

  export default {
    name: 'SearchFilterItem',
    components: { BaseIcon },
    props: {
      filterItem: {
        type: Object,
        default: () => {},
      },
      extraLabel: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('SwSearchStore', ['searchText']),

      shouldShowChildrenCount() {
        return this.filterItem.level === 1 && !this.extraLabel;
      },
      shouldShowExpandedIcon() {
        return !this.extraLabel;
      },
      shouldGreyOut() {
        return this.searchText?.trim()?.length && !this.filterItem?.isFound;
      },
    },
    mounted() {
      this.$emit('filterItemEvent', {
        type: EventEnum.FILTER_ITEM_MOUNTED,
        element: this.$el,
      });
    },
    beforeDestroy() {
      this.$emit('filterItemEvent', {
        type: EventEnum.FILTER_ITEM_BEFORE_DESTROY,
        element: this.$el,
      });
    },
    methods: {
      getLabel(label) {
        if (this.extraLabel) {
          return `${label} | ${this.extraLabel}`;
        }
        return label;
      },
      toggleExpanded() {
        this.$store.dispatch('SwFilterStore/toggleExpanded', this.filterItem);
        this.$nextTick(() => {
          this.$refs.focusMagnet.focus();
        });
      },
      async toggleChecked() {
        if (this.filterItem.checkboxDisabled) {
          return;
        }
        await this.$store.dispatch(
          'SwFilterStore/toggleChecked',
          this.filterItem
        );
        this.$nextTick(() => {
          this.$refs.focusMagnet.focus();
        });
        this.performSearch();
      },
      performSearch: debounce(function() {
        this.$store.dispatch('SwSearchStore/performSearch', {
          fromFilterReq: true,
        });
      }, PERFORM_SEARCH_DEBOUNCE),
    },
  };
</script>

<style lang="less" src="./SearchFilter.less"></style>
